// Generated by Framer (a2c2d6f)

import { addFonts, cx, CycleVariantState, getFonts, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["ZtT6PJCW5"];

const variantClassNames = {ZtT6PJCW5: "framer-v-1fnzntv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "ZtT6PJCW5", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ZtT6PJCW5", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-njhVZ", classNames)} style={{display: "contents"}}>
<Link href={"https://stfn.lemonsqueezy.com/checkout/buy/32fecd8c-5229-4f30-90cf-3e7f351b502b"} openInNewTab><motion.a {...restProps} className={`${cx("framer-1fnzntv", className)} framer-1ullic2`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ZtT6PJCW5"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderTopLeftRadius: 11, borderTopRightRadius: 11, boxShadow: "0px 0.48174984141951427px 1.252549587690737px -1.25px rgba(0, 0, 0, 0.28796), 0px 1.8308266425947657px 4.760149270746391px -2.5px rgba(0, 0, 0, 0.25423), 0px 8px 20.8px -3.75px rgba(0, 0, 0, 0.1)", ...style}} transition={transition}><motion.div className={"framer-8b7mhp-container"} layoutDependency={layoutDependency} layoutId={"HOubIIAq4-container"} transition={transition}><Phosphor color={"rgb(38, 38, 38)"} height={"100%"} iconSearch={"House"} iconSelection={"Lightning"} id={"HOubIIAq4"} layoutId={"HOubIIAq4"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"}/></motion.div><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNzAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "13px", "--framer-font-weight": "700", "--framer-letter-spacing": "-0.4px", "--framer-line-height": "1.75em", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Get template — $39</motion.p></React.Fragment>} className={"framer-1tvolip"} fonts={["GF;Inter-700"]} layoutDependency={layoutDependency} layoutId={"T_DXRkEAJ"} style={{"--extracted-r6o4lv": "rgb(35, 31, 32)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-njhVZ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-njhVZ .framer-1ullic2 { display: block; }", ".framer-njhVZ .framer-1fnzntv { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 7px; height: min-content; justify-content: center; overflow: hidden; padding: 8px 14px 8px 12px; position: relative; text-decoration: none; width: min-content; will-change: transform; }", ".framer-njhVZ .framer-8b7mhp-container { flex: none; height: 18px; position: relative; width: 18px; }", ".framer-njhVZ .framer-1tvolip { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-njhVZ .framer-1fnzntv { gap: 0px; } .framer-njhVZ .framer-1fnzntv > * { margin: 0px; margin-left: calc(7px / 2); margin-right: calc(7px / 2); } .framer-njhVZ .framer-1fnzntv > :first-child { margin-left: 0px; } .framer-njhVZ .framer-1fnzntv > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 39
 * @framerIntrinsicWidth 170
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 */
const FramerhWch4kgQQ: React.ComponentType<Props> = withCSS(Component, css, "framer-njhVZ") as typeof Component;
export default FramerhWch4kgQQ;

FramerhWch4kgQQ.displayName = "Framer CTA";

FramerhWch4kgQQ.defaultProps = {height: 39, width: 170};

addFonts(FramerhWch4kgQQ, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/hWch4kgQQ:default", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf", weight: "700"}, ...PhosphorFonts])